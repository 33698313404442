
<template>
    <div class='component-sfilter'>
        <Input placeholder="过滤组件" @on-change='getInput' />
    </div>
</template>

<script>
    export default {
        data() {
            return {
                 keyword: ''
            }
        },
        methods: {
            getInput(val) {
                this.keyword = val.target.value;
                this.$store.commit('decorate/setKeyword',this.keyword)
            },
        }
    }
</script>

<style lang="scss" scoped>
    .component-sfilter {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(55px, 0);
        width: 150px;
    }
</style>